// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-capacitaciones-js": () => import("./../src/templates/capacitaciones.js" /* webpackChunkName: "component---src-templates-capacitaciones-js" */),
  "component---src-templates-conferencias-js": () => import("./../src/templates/conferencias.js" /* webpackChunkName: "component---src-templates-conferencias-js" */),
  "component---src-templates-noticias-js": () => import("./../src/templates/noticias.js" /* webpackChunkName: "component---src-templates-noticias-js" */),
  "component---src-templates-nuevos-socios-page-js": () => import("./../src/templates/nuevos-socios-page.js" /* webpackChunkName: "component---src-templates-nuevos-socios-page-js" */),
  "component---src-templates-sociedad-page-js": () => import("./../src/templates/sociedad-page.js" /* webpackChunkName: "component---src-templates-sociedad-page-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-capacitaciones-index-js": () => import("./../src/pages/capacitaciones/index.js" /* webpackChunkName: "component---src-pages-capacitaciones-index-js" */),
  "component---src-pages-conferencias-index-js": () => import("./../src/pages/conferencias/index.js" /* webpackChunkName: "component---src-pages-conferencias-index-js" */),
  "component---src-pages-contacto-examples-js": () => import("./../src/pages/contacto/examples.js" /* webpackChunkName: "component---src-pages-contacto-examples-js" */),
  "component---src-pages-contacto-file-upload-js": () => import("./../src/pages/contacto/file-upload.js" /* webpackChunkName: "component---src-pages-contacto-file-upload-js" */),
  "component---src-pages-contacto-index-js": () => import("./../src/pages/contacto/index.js" /* webpackChunkName: "component---src-pages-contacto-index-js" */),
  "component---src-pages-contacto-thanks-js": () => import("./../src/pages/contacto/thanks.js" /* webpackChunkName: "component---src-pages-contacto-thanks-js" */),
  "component---src-pages-noticias-index-js": () => import("./../src/pages/noticias/index.js" /* webpackChunkName: "component---src-pages-noticias-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

